import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'detail-contact'}>
            <Container>
                <Row>
                    <Col sm={8}>
                        <Title margin={'0 0 10px 0'} text={data?.title}/>
                        <p>{reactHtmlParser(data?.description)}</p>
                    </Col>

                    <Col sm={4} className={'d-flex justify-content-end align-items-end'}>
                        <Button src={'/contact'} text={'Contact Us'}/>
                    </Col>
                </Row>


            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  border-top: 1px solid #E9E9E9;
  padding-top: 80px;
  padding-bottom: 80px;

  p {
    font-size: 20px;
    line-height: 32px;

    a {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    .col-sm-8 {
      min-width: 100%;
    }

    .col-sm-4 {
      min-width: 100%;
      justify-content: left !important;
    }

    .dc-btn {
      margin-top: 40px;

    }
  }

`;

export default MyComponent;
