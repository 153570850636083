import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchPosts, fetchPostDetail} from "../../api/redux/post";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import Button from "../../components/Button";
import {Img} from "../../components/Img";
import Title from "../../components/Title";
import InnerBanner from "../../components/InnerBanner";
import {Loading} from "../../components/Loading";
import {Container} from "react-bootstrap";
import Banner from "../../components/home/Banner";
import Counter from "../../components/home/Counter";
import TextImg from "../../components/TextImg";
import Client from "../../components/home/Client";
import FeatureProject from "../../components/home/FeatureProject";
import Inquires from "../../components/home/Inquires";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {fetchData} from "../../api/redux/home";
import reactHtmlParser from "react-html-parser";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ParallaxProvider} from "react-scroll-parallax";
import Elements from "../../components/home/Elements";
import Certificates from "../../components/home/Certificates";
import Elements2 from "../../components/home/Elements2";

const Home = ({offset}) => {
    gsap.registerPlugin(ScrollTrigger);
    const dispath = useDispatch()
    const location = useLocation();
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchData([api_url]))
    }, [])

    let getPost = useSelector(state => state.home)


    const banner = getPost?.posts?.[0]?.banner;
    const counter = getPost?.posts?.[0]?.counter;
    const about = getPost?.posts?.[0]?.about;
    const client = getPost?.posts?.[0]?.client;
    const inquiry = getPost?.posts?.[0]?.inquiry;
    const featured = getPost?.posts?.[0]?.featured;
    const certificate = getPost?.posts?.[0]?.certificate;
    const element = getPost?.posts?.[0]?.element;

    console.log('getPost', getPost)

    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [location.pathname, getPost])


    return (
        <ParallaxProvider>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Saudi Landscape</title>
                    <meta name="description" content="Design | Build | O&M"/>
                </Helmet>

                <StyledSection>
                    {getPost?.loading && <Loading/>}
                    <Banner data={banner}/>
                    <Counter data={counter}/>
                    <TextImg textOverflow title={about?.title} img={about?.image}
                             link={'/about-us'}
                             desc={about?.description}/>
                    <Client data={client}/>
                    <Certificates data={certificate}/>
                    <FeatureProject data={featured}/>
                    {/*<Elements/>*/}
                    <Elements2 element={element}/>
                    <Inquires data={inquiry}/>
                </StyledSection>
            </HelmetProvider>
        </ParallaxProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
