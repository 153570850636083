import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from "../Button";
import Title from "../Title";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postData} from "../../api/redux/career";

const MyComponent = () => {


    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.CAREER_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.number);
        formData.append('your-message', e?.message);
        formData.append('your-portfolio', e?.portfolio);
        formData.append('your-cv', e?.cv?.[0]);
        dispatch(postData([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (isSubmitSuccessful) {
            success('CV Submitted')
            reset()
        }
    }, [isSubmitSuccessful])


    return (
        <StyledComponent className={'career-apply pb-120'}>
            <Container>
                <Row>
                    <Col>

                        <Title margin={'0 0 40px 0'} text={'Apply now!'}/>

                        <Form method="post" enctype="multipart/form-data" className={'career-form'}
                              onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                            <div className={'group'}>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    }

                                })} type="text" placeholder="Full name *"/>
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </div>
                            <div className={'group'}>
                                <Form.Control {...register('email', {
                                    required: {
                                        value: true,
                                        message: "Please enter a valid email address"
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })} type="email" placeholder="Email *"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </div>
                            <div className={'group'}>
                                <Form.Control {...register('number')} type="number" placeholder="Phone number"/>
                            </div>
                            <div className={'group'}>
                                <Form.Control {...register('portfolio')} type="text" placeholder="Portfolio Link"/>
                            </div>
                            <div className="form-bottom">
                                <div className="form-bottom__upload">
                                    <div className="form-bottom__upload__button">
                                        <input accept={'application/pdf'} {...register('cv')} type="file"/>
                                        <img src="/images/static/upload.svg" alt=""/> <span>Upload Your CV</span>
                                        <p>{watch('cv')?.[0]?.name}</p>
                                    </div>
                                </div>
                            </div>

                            {errors.email || errors.name ?
                                <div className="button-group disabled" style={{margin: 'auto'}}>
                                    <Button margin={'60px auto 0 0'} text={'Submit'}/>
                                </div>
                                :
                                <div onClick={handleSubmit(handleFormSubmit)} className="button-group"
                                     style={{margin: 'auto'}}>
                                    <Button margin={'60px auto 0 0'} text={'Submit'}/>
                                </div>

                            }

                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  .career-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .group {
      width: calc(50% - 60px);
    }
  }

  .form-group {
    width: calc(50% - 60px);
  }

  .textarea-group {
    min-width: 100%
  }

  .group {
    p {
      bottom: 4px;
    }
  }

  .form-control {
    border: none;
    border-bottom: 1px solid rgba(194, 194, 194, 0.3);
    border-radius: 0;
    padding: 20px 20px 24px 0;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 30px;
    }

    ::placeholder {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      color: #a4a4a4;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    width: 100%;
  }

  .form-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__radio {
      display: flex;

      .form-group {
        width: 100%;
        display: flex;
      }

      .form-check {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        label {
          font-size: 16px;
          line-height: 32px;
          font-weight: 400;
          margin-top: 4px;
        }

        &:nth-of-type(1) {
          margin-right: 90px;

        }
      }
    }
  }

  .form-bottom__upload {
    cursor: pointer;

    &__button {
      width: 300px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #545456;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      transition: .5s ease;

      p {
        position: absolute;
        left: 0;
        font-size: 12px;
        line-height: 13px;
        top: 108%;
      }

      input {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 2;
        top: 0;
        left: 0;

        &:after {
          content: '';
          height: 100%;
          width: 100%;
          z-index: 2;
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      img {
        margin-right: 15px;
      }

      span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
      }

      &:hover {
        background-color: rgb(233, 233, 233);
      }
    }
  }

  @media (max-width: 767px) {

    .group {
      min-width: 100% !important;
      display: block;
    }

    //.group p {
    //  bottom: -15px;
    //}
    //
    //.group:nth-of-type(1) p {
    //  bottom: -15px;
    //}
    form {
      display: block;

      .group {
        min-width: 100% !important;
        width: 100%;

      }
    }


    .form-bottom {
      .form-bottom__radio {
        min-width: 100%;
        margin-top: 10px;
      }
    }

    .form-bottom__upload {
      min-width: 100%;
      margin-top: 10px;
      @media (max-width: 650px) {
        &__button {
          width: 100%;
        }
      }

    }

    .group {
      width: 100%;

      .dc-btn {
        margin-top: 40px;
      }
    }
  }

`;

export default MyComponent;
