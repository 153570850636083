import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {Navigation, Autoplay} from "swiper";

const MyComponent = ({data}) => {

    let sliderRefIcon = useRef();
    const NewsgoNext = () => {
        if (sliderRefIcon.current && sliderRefIcon.current.swiper) {

            sliderRefIcon.current.swiper.slideNext()

        }
    };

    const NewsgoPrev = () => {
        if (sliderRefIcon.current && sliderRefIcon.current.swiper) {
            sliderRefIcon.current.swiper.slidePrev()
        }
    };


    const target = () => {
        if (window.innerWidth > 1024) {
            setTimeout(() => {
                const allActive = document.querySelectorAll('.swiper-slide.swiper-slide-visible');
                if (document.querySelector('.targeted')) {
                    document.querySelector('.targeted')?.classList.remove('targeted')
                }
                allActive?.[3]?.classList?.add('targeted')
            }, 300)
        } else {
            setTimeout(() => {
                const allActive = document.querySelectorAll('.swiper-slide.swiper-slide-visible');
                if (document.querySelector('.targeted')) {
                    document.querySelector('.targeted')?.classList.remove('targeted')
                }
                allActive?.[2]?.classList?.add('targeted')
            }, 300)
        }
    }


    return (
        <StyledComponent className={'client'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title text={data?.title} margin={'0 0 40px 0'}/>
                    </Col>
                </Row>
                <div className="client__slider">
                    <Swiper ref={sliderRefIcon} spaceBetween={15}
                            slidesPerView={3}
                            slideNextClass={'--next'}
                            allowSlideNext={true}
                            slidePrevClass={'--prev'}
                            allowSlidePrev={true}
                            allowTouchMove={true}
                            longSwipesMs={900}
                            speed={900}
                            slidesPerGroup={2}
                            watchSlidesProgress={true}
                            slidesPerGroupAuto={true}
                        // autoplay={false}
                            autoplay={{
                                delay: 8000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                // 650: {
                                //     slidesPerView: 3,
                                //     // spaceBetween: 15,
                                // },
                                1024: {
                                    slidesPerView: 4,
                                    // spaceBetween: 30,
                                },
                            }}
                            navigation={{
                                prevEl: '.client-slider .prev_swippers',
                                nextEl: '.client-slider .next_swippers  ',
                            }}
                            modules={[Navigation, Autoplay]}
                            onSlideChange={(swiper) => target()}
                    >
                        {
                            data?.slider && data?.slider?.length > 0 && data?.slider?.map((e, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className="client__slider__single">
                                            <img src={e?.logo} alt=""/>
                                        </div>
                                    </SwiperSlide>
                                );
                            })

                        }


                    </Swiper>
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .client__slider {
    &__single {
      text-align: center;
      //border-right: 1px solid rgba(84, 84, 86, 0.3);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 80px;
      }
    }
  }

  .swiper-slide {
    border-right: 1px solid rgba(84, 84, 86, 0.3);
  }

  .targeted {
    border-color: transparent;
  }

  //.swiper-slide-visible:nth-child(4) {
  //  border-color: transparent !important;
  //  //background-color: red;
  //}

  @media (max-width: 767px) {
    img {
      height: 50px;
    }
  }
`;

export default MyComponent;
