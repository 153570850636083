import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import CertificateBox from "./CertificateBox";
import Title from "../Title";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";


const MyComponent = ({data}) => {

    const PhotoItem = ({image, thumb, group, data, category, title,deskTitle, mobTitle, link, date}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <CertificateBox img={image} title={title} deskTitle={deskTitle} mobTitle={mobTitle} photoGallery/>
        </LightgalleryItem>

    );
    const PhotoItem2 = ({image, thumb, group}) => (
        <div style={{maxWidth: "250px", width: "200px", padding: "5px"}}>
            <LightgalleryItem group={group} src={image} thumb={thumb}>
                <img src={image} style={{width: "100%"}}/>
            </LightgalleryItem>
        </div>
    );
    const datas = data?.list.map((item, index) => ({
        deskTitle: item.deskTitle,
        mobTitle: item.mobTitle,
        title: item.title,
        gallery: [
            {
                image: item.image,
                ID: index,
            },
        ],
    }));



    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container>
                <Title text={data?.title} margin={'0 0 40px'}/>
                <Row>
                    {datas?.length > 0 && datas?.map(i => (
                        <LightgalleryProvider
                            lightgallerySettings={
                                {
                                    download: false,
                                    fullScreen: true,
                                    share: false
                                }
                            }
                        >

                            <Col md={6}>
                                <PhotoItem key={i?.id} image={i?.gallery[0]?.image}
                                           title={i?.title} deskTitle={i?.deskTitle}
                                           mobTitle={i?.mobTitle}
                                           group={`group${i?.gallery?.[0]?.ID}`}/>
                                <div
                                    style={{
                                        display: "none",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    {i?.gallery ? i?.gallery?.slice(1)?.map((element, k) => (
                                        <PhotoItem2 key={k} image={element?.image}
                                                    group={`group${element?.ID}`}/>
                                    )) : ''}
                                </div>

                            </Col>
                        </LightgalleryProvider>
                    ))}
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .col-md-6{
    margin-bottom: 40px;
    &:last-child{
      margin-bottom: 0;
    }
  }
    @media(max-width: 767px){
      .col-md-6{
        margin-bottom: 40px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  
  
`;

export default MyComponent;
