import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {Img} from "../Img";

const MyComponent = ({img, deskTitle, mobTitle, title}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [titles, setTitle] = useState(windowWidth > 767 ? deskTitle : mobTitle);

    // Update title based on window width
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
            setTitle(newWidth > 767 ? deskTitle : mobTitle);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [deskTitle, mobTitle]);
    return (
        <StyledComponent>
            <div className={'img-wrapper'}>
                <Img src={img}/>
            </div>
            {titles &&
                <h5 className={'title'}>{titles}</h5>
            }
            <h5 className={'title'}>{title}</h5>


        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  .img-wrapper {
    position: relative;
    padding-top: calc(400 / 570 * 100%);
    border: 1px solid #E9E9E9;
  }

  h5 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    margin-top: 20px;
  }
`;

export default MyComponent;
