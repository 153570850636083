import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Address from "../../components/contact/Address";
import Inquires from "../../components/contact/Inquires";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/contact";
import {Loading} from "../../components/Loading";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT
        dispath(fetchData([api_url]))
    }, [])

    let getPost = useSelector(state => state.contact)

    const banner = getPost?.posts?.[0]?.banner;
    const find = getPost?.posts?.[0]?.find;
    const inquiry = getPost?.posts?.[0]?.inquiry;


    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [getPost])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Contact | Saudi Landscape</title>
                <meta name="description" content="Design | Build | O&M"/>
            </Helmet>

            {getPost?.loading && <Loading/>}

            <StyledComponent>
                <InnerBanner text={banner?.subtitle} title={banner?.title}
                             img={banner?.image}/>
                <Address data={find}/>
                <Inquires data={inquiry}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .inquires {
    padding-top: 0 !important;
  }
`;

export default MyComponent;
