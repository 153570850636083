import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {gsap, TimelineLite, CSSPlugin} from "gsap";

import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import {hover} from "../styles/globalStyleVars";
import {useSelector} from "react-redux";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({offset}) => {
    gsap.registerPlugin(CSSPlugin);
    const tl = new TimelineLite()
    const location = useLocation();

    let getPost = useSelector(state => state.global)

    useEffect(() => {

        const hamburger = document.querySelectorAll('.hamburger-action');

        hamburger.forEach(e => {
            e.addEventListener('click', () => {

                if (!document.body.classList.contains('menu-open')) {
                    tl.to('.main-menu__items', {
                        display: 'block',
                        duration: 0
                    }).to('.main-menu__items', {
                        duration: 1,
                        x: 0,
                        ease: "Expo.easeInOut"
                    }).to('.main-menu__items li a', {
                        duration: .5,
                        y: 0,
                        opacity: 1,
                        stagger: .15
                    }, '-=.8').to('.main-menu__items__copyright', {
                        duration: .5,
                        y: 0,
                        opacity: 1,
                    }, '-=1')
                    document.body.classList.add('menu-open')
                    disableBodyScroll(e)
                } else {

                    tl.to('.main-menu__items__copyright', {
                        duration: .4,
                        y: 20,
                        opacity: 0,
                    }).to('.main-menu__items li a', {
                        duration: .4,
                        y: 20,
                        opacity: 0,
                    }, '-=.3').to('.main-menu__items', {
                        duration: .6,
                        x: '100%',
                        ease: "Expo.easeInOut"
                    }, '-=.3').to('.main-menu__items', {
                        display: 'none',
                        duration: 0
                    })
                    document.body.classList.remove('menu-open');
                    clearAllBodyScrollLocks(e)
                }
            })
        })


        window.addEventListener('click', e => {
            if (document.body.classList.contains('menu-open')) {
                if (!e.target.matches('.main-menu__items,.main-menu__right__hamburger,.main-menu__items ul,.main-menu__items ul li,.main-menu__items__copyright p,.mb-hambuerger,.mb-hambuerger img')) {
                    tl.to('.main-menu__items__copyright', {
                        duration: .4,
                        y: 20,
                        opacity: 0,
                    }).to('.main-menu__items li a', {
                        duration: .4,
                        y: 20,
                        opacity: 0,
                    }, '-=.3').to('.main-menu__items', {
                        duration: .6,
                        x: '100%',
                        ease: "Expo.easeInOut"
                    }, '-=.3').to('.main-menu__items', {
                        display: 'none',
                        duration: 0
                    })
                    document.body.classList.remove('menu-open');
                    clearAllBodyScrollLocks(e)
                }
            }
        })
    }, [])


    //---- on scroll menu fixed action
    useEffect(() => {
        window.addEventListener('scroll', () => {

            if (window.scrollY > 10) {
                document.body.classList.add("scroll-up")
            } else {
                document.body.classList.remove("scroll-up")
            }
        })

        // if (document.body.classList.contains("scroll-down")) {
        //     document.body.classList.remove("scroll-down");
        // }
    }, []);

    // useEffect(() => {
    //     const body = document.body;
    //     const scrollUp = "scroll-up";
    //     const scrollDown = "scroll-down";
    //     let lastScroll = 0;
    //     let howMuchScroll = 150;
    //     if (window.screen.width < 991) {
    //         howMuchScroll = 80;
    //     } else {
    //         howMuchScroll = 150;
    //     }
    //
    //     window.addEventListener("scroll", () => {
    //         let currentScroll = window.pageYOffset;
    //
    //         if (currentScroll <= 0) {
    //             body.classList.remove(scrollUp);
    //             return;
    //         }
    //         if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    //             // down
    //             body.classList.remove(scrollUp);
    //             body.classList.add(scrollDown);
    //         } else if (
    //             currentScroll < lastScroll &&
    //             body.classList.contains(scrollDown)
    //         ) {
    //             // up
    //             body.classList.remove(scrollDown);
    //             body.classList.add(scrollUp);
    //         }
    //         lastScroll = currentScroll;
    //     });
    // }, []);


    // view port calculation
    useEffect(() => {
        if (window.innerWidth < 769) {
            const currentHeight = window.innerHeight;
            document.querySelector('.main-menu__items').style.height = `${currentHeight}px`
            document.querySelector('.main-menu__items ul').style.height = `${currentHeight - 220}px`
        }
    }, [])


    return (
        <StyledComponent className={'main-menu'}>
            <Container fluid style={{paddingLeft: offset + 'px', paddingRight: 0}}>
                <Row>
                    <Col xs={6}>
                        <div className="main-menu__logo">
                            <Link to={'/'}><img src="/images/static/logo.svg" alt=""/></Link>
                            {/*<Link to={'/'}><img src="/images/static/logo-black.svg" alt=""/></Link>*/}
                            <Link to={'/'}><img src="/images/static/logo-black.svg" alt=""/></Link>
                        </div>
                    </Col>

                    <Col xs={6} className={'main-menu__right'}>
                        <ul>
                            <li>
                                {/*<a href="callto:+966541100567">+966541100567</a>*/}
                                <a href="https://wa.me/+966541100567" target={"_blank"}>+966541100567</a>
                            </li>
                            <li>
                                <a href="mailto:info@saudilandscape.com">info@saudilandscape.com</a>
                            </li>
                            <li className={'main-menu__right__hamburger hamburger-action'}>
                                <span/>
                                <span/>
                            </li>
                        </ul>

                        <div className="mb-hambuerger hamburger-action">
                            <img src="/images/static/hamburger.svg" alt=""/>
                            <img src="/images/static/close.svg" alt=""/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className="menu-overlay"/>

            {/*toggle slide menu  */}
            <div className="main-menu__items">
                <ul>
                    <li className={location?.pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link></li>
                    <li className={location?.pathname === '/about-us' ? 'active' : ''}><Link to={'/about-us'}>About
                        Us</Link></li>
                    <li className={location?.pathname === '/expertise' ? 'active' : ''}><Link
                        to={'/expertise'}>Expertise</Link></li>
                    <li className={location?.pathname === '/projects' ? 'active' : ''}><Link
                        to={'/projects'}>Projects</Link></li>
                    <li className={location?.pathname === '/vendors' ? 'active' : ''}><Link
                        to={'/vendors'}>Vendors</Link></li>
                    <li className={location?.pathname === '/careers' ? 'active' : ''}><Link
                        to={'/careers'}>Careers</Link></li>
                    <li className={location?.pathname === '/press' ? 'active' : ''}><Link to={'/press'}>Press
                        </Link></li>
                    <li className={location?.pathname === '/certificate' ? 'active' : ''}><Link to={'/certificate'}>Certificate
                        </Link></li>
                    <li className={location?.pathname === '/contact' ? 'active' : ''}><Link to={'/contact'}>Contact
                        us</Link></li>
                </ul>

                <div className="main-menu__items__copyright">
                    <p>{reactHtmlParser(getPost?.posts?.[0]?.address)}</p>
                </div>
            </div>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  left: 0;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  transition: all 0.4s ease;
  //background-color: rgba(255, 255, 255, 0.90);
  overflow: hidden;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 17%, rgb(0 0 0 / 60%) 93%);
  //  top: 0;
  //  left: 0;
  //  height: 100%;
  //  width: 100%;
  //}

  //.container-fluid {
  //  position: relative;
  //  z-index: 9999;
  //}

  .main-menu__logo {
    height: 100%;

    a {
      display: flex;
      align-items: center;
      height: 100%;

      &:nth-of-type(2) {
        display: none;
      }
    }

  }

  .main-menu__right {
    display: flex;
    justify-content: right;

    ul {
      display: flex;

      li {
        a {
          color: #e9e9e9;
          //color: #191818;
          font-size: 14px;
          font-weight: 300;
          line-height: 209px;
        }

        &:nth-of-type
        (1) a {
          border-left: 1px solid rgba(255, 255, 255, 0.15);
          //border-left: 1px solid rgba(25, 24, 24, 0.15);
        }

        &:not(:nth-last-of-type(1)) a {
          padding: 0 50px;
          height: 100px;
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(255, 255, 255, 0.15);
          //border-left: 1px solid rgba(25, 24, 24, 0.15);
        }

      }
    }

    &__hamburger {
      width: 145px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      z-index: 99999;

      span {
        width: 50px;
        height: 2px;
        background-color: #545456;
        display: block;
        margin: 6px 0;
        position: relative;
        transition: all .6s ease;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: #fff;
          //background-color: #191818;
          transition: all .4s ease;
        }
      }

      &:hover {
        span {
          background-color: ${hover};

          &:nth-of-type(1):after {
            transform: translateX(-10px);
            background-color: #BADA55;
          }

          &:nth-of-type(2):after {
            transform: translateX(10px);
            background-color: #BADA55;
          }
        }
      }
    }
  }

  .main-menu__items {
    height: 100vh;
    width: 60%;
    position: fixed;
    background-color: #FFF;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    padding: 80px 100px;
    display: none;
    transform: translateX(100%);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.20);

    ul {
      //display: flex;
      //flex-wrap: wrap;
      //flex-direction: column;
      //justify-content: space-between;
      //height: 100%;
      position: absolute;
      top: 80px;
      bottom: 80px;
      height: 75vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }


      li {
        a {
          font-size: 34px;
          line-height: 50px;
          text-transform: capitalize;
          color: #212121;
          transform: translateY(20px);
          opacity: 0;
          display: flex;
          width: fit-content;
        }

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 40px;
        }

        &.active a {
          color: ${hover};
        }
      }
    }


    &__copyright {
      position: absolute;
      right: 100px;
      bottom: 90px;
      opacity: 0;
      transform: translateY(20px);
      width: 30%;


      p {
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #545456;
      }

    }
  }

  @media (min-width: 992px) {
    .mb-hambuerger img {
      display: none;
    }
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 995;
    display: none;
  }

  @media (max-width: 991px) {
    .container-fluid {
      padding-left: 15px !important;
    }

    height: 60px;
    //border: none;
    &:after {
      content: '';
      position: absolute;
      height: 60px;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
      z-index: 999999;
      //display: none;
    }

    .row {
      z-index: 999999999;
      position: relative;
    }

    .main-menu__logo {
      img {
        height: 30px;
      }
    }

    .main-menu__right {
      ul {
        display: none;
      }
    }

    .mb-hambuerger {
      padding-right: 15px;


      img {
        &:nth-of-type(2) {
          display: none;
        }
      }
    }

    .main-menu__items {
      width: 100%;
      padding: 100px 0px 20px 15px;

      ul {
        height: calc(100vh - 220px);
        overflow: auto;
        padding-bottom: 50px;
        position: unset;

        li a {
          font-size: 30px;
          line-height: 50px;
        }
      }

      ul li:not(:nth-last-of-type(1)) {
        margin-bottom: 20px;
      }
    }

    .main-menu__items__copyright {
      bottom: 0px;
      left: 0px;
      width: 100%;
      background-color: #fff;
      padding: 0px 15px 50px 15px;

      p {
        border-top: 1px solid #E9E9E9;
        text-align: left;
        padding-top: 30px;
        padding-right: 80px;
      }
    }
  }


`;

export default MyComponent;
