import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/project";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import ProjectBanner from "../../components/InnerBanner";
import TextImg from "../../components/project/TextImg";
import Button from "../../components/Button";
import Contact from "../../components/project/Contact";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyComponent = () => {
    const dispatch = useDispatch()
    const getData = useSelector(store => store.project)
    let {slug} = useParams();
    gsap.registerPlugin(ScrollTrigger);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECT_DETAILS
        dispatch(fetchPostDetail([api_url + `/${slug}`]))
    }, [])

    const banner = getData?.detail?.[0];
    const zigzag = getData?.detail?.[0]?.list?.Desc;
    const cta = getData?.detail?.[0]?.cta;

    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [getData])


    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${banner?.post_title ? banner?.post_title + ' | Saudi Landscape' : 'Saudi Landscape'} `}</title>
                <meta name="description" content="Design | Build | O&M"/>

            </Helmet>
            {getData?.detailLoading && <Loading/>}
            <StyledComponent>
                <ProjectBanner text={banner?.list?.subtitle} title={banner?.post_title}
                               img={banner?.list?.banner}/>
                <div className="zig-zag pt-120 pb-120">
                    {
                        zigzag && zigzag?.length > 0 &&
                        zigzag?.map((e, i) => {
                            return (
                                <TextImg key={i}
                                         desc={e?.desc}
                                         img={e?.img}/>
                            );
                        })
                    }

                    <div className="button-group">
                        <Button src={'/projects'} width={'250px'} text={'View All Projects'}/>
                    </div>
                </div>

                <Contact data={cta}/>

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  .inner-banner {
    padding: 0;
    height: calc(100vh - 100px);
  }

  .inner-banner__content {
    width: 90%;
    display: flex;
    flex-direction: column-reverse;

    h2 {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 991px) {
    .inner-banner {
      padding: 0;
      height: calc(100vh - 60px);
    }
  }

  .zig-zag {
    width: 100%;

    .text-img {
      padding: 0 !important;
      padding-bottom: 0px !important;

      .text-img__desc {
        max-width: 50%;
        min-width: 50%;
        padding-right: 80px;

        p {
          -webkit-line-clamp: unset;
        }
      }

      .text-img__img {
        margin: 0;
        max-width: 50%;
        min-width: 50%;
        padding-left: 0px;

        &__wrap {
          padding-top: calc(600 / 585 * 100%);
        }
      }

      &:nth-of-type(even) {
        .row {
          flex-direction: row-reverse;

          .text-img__desc {
            max-width: 50%;
            min-width: 50%;
            margin: 60px 0 0 0;
            padding-left: 80px;
            padding-right: 15px;
          }

          .text-img__img {
            margin: 0;
            max-width: 50%;
            min-width: 50%;
            padding-left: 15px;
            padding-right: 0px;

          }
        }
      }
    }

    @media (max-width: 767px) {
      .text-img__desc {
        min-width: 100% !important;
        margin-bottom: 60px !important;
        margin-top: 30px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
      }

      .text-img__img {
        min-width: 100% !important;
        padding: 0 15px !important;
      }

      .text-img {
        .row {
          flex-direction: column-reverse !important;
        }
      }

      .button-group {
        margin-top: 0px;
      }
    }
  }

  .button-group {
    width: 100%;
    padding: 0 15px;
    margin-top: 60px;

    .dc-btn {
      margin: auto;
    }
  }

  @media (max-width: 550px) {
    .text-img .row {
      margin-bottom: 0 !important;
    }
  }


`;

export default MyComponent;
