import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pb-120'}>
            <Container>
                <Title margin={'0 0 40px 0'} text={data?.title}/>
                <div className={'sectors'}>
                    {
                        data?.list && data?.list?.length>0 &&
                        data?.list?.map((e, i)=>{
                            return(
                                <div className={'sectors__single'} key={i}>
                                    <div className={'sectors__single__box'}>
                                        <h5>{reactHtmlParser(e?.list)}</h5>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .sectors {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    &__single {
      border-top: 0.5px solid #CBCBCC;
      border-right: 0.5px solid #CBCBCC;
      &:first-child{
        border-left: 0.5px solid #CBCBCC;
      }
      :nth-child(4n + 1){
        border-left: 0.5px solid #CBCBCC;
      }
      &:nth-last-child(-n+4) {
        border-bottom: 0.5px solid #CBCBCC;
      }

      &__box {
        height: 200px;
        width: 290px;
        display: flex;
        justify-content: center;
        align-items: center;
        h5 {
          font-weight: 500;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          width: 50%;
        }
      }
    }
    @media(max-width: 767px){
      grid-template-columns: repeat(2, 1fr);
      &__single{
        border: 0.5px solid #CBCBCC !important;
        &:nth-child(even){
          border-left: 0 !important;
        }
        &:nth-child(2){
          border-top: 0.5px solid #CBCBCC !important;
        }
        &:nth-child(n + 3){
          border-top: 0 !important;
        }
        &__box{
          height: 120px;
          width: auto;
          h5{
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            width: 100%;
          }
        }
      }
    }
  }
`;

export default MyComponent;
