import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import Button from "../Button";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import reactHtmlParser from "react-html-parser";
import {Navigation, Autoplay} from "swiper";


const MyComponent = ({desc, title, subTitle, link, img, textOverflow}) => {


    let sliderRefIcon = useRef();
    return (
        <StyledComponent textOverflow={textOverflow} className={'text-img pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={6} className={'text-img__desc'}>
                        {title && <Title margin={'0 0 40px 0'} text={title}/>}
                        {subTitle && <h4 className={'fade-up'}>{subTitle}</h4>}
                        <p className={'fade-up'}>{reactHtmlParser(desc)}</p>
                        {link && <Button src={link} margin={'60px 0 0 0'} width={'200px'} text={'about us'}/>}
                    </Col>

                    <Col sm={{span: 5, offset: 1}} className={'text-img__img'}>

                        {img?.length > 0 &&
                            <Swiper ref={sliderRefIcon} spaceBetween={15}
                                    slidesPerView={1}
                                    slideNextClass={'--next'}
                                    allowSlideNext={true}
                                    slidePrevClass={'--prev'}
                                    allowSlidePrev={true}
                                    allowTouchMove={true}
                                    longSwipesMs={900}
                                    speed={900}
                                    slidesPerGroup={1}
                                    watchSlidesProgress={true}
                                    slidesPerGroupAuto={true}
                                // autoplay={false}
                                    autoplay={{
                                        delay: 8000,
                                        disableOnInteraction: false,
                                    }}

                                    navigation={{
                                        prevEl: '.client-slider .prev_swippers',
                                        nextEl: '.client-slider .next_swippers  ',
                                    }}
                                    modules={[Navigation, Autoplay]}

                            >
                                {img?.length > 0 && img?.map((e, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="text-img__img__wrap">
                                            <Img src={e?.img}/>
                                        </div>

                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        }
                        {link && <Button src={link} margin={'30px 0 0 0'} width={'200px'} text={'about us'}/>}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  .row {
    margin-bottom: 60px;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    color: #212121;
    margin-bottom: 20px;
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }

  .text-img__img {
    &__wrap {
      position: relative;
      padding-top: calc(600 / 500 * 100%);
    }

    @media (min-width: 769px) {
      .dc-btn {
        display: none;
      }
    }
  }


  @media (max-width: 768px) {
    .text-img__desc, .text-img__img {
      min-width: 100%;
      margin: 0;
    }

    .text-img__desc {
      margin-bottom: 40px;

      .dc-btn {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .text-img__desc {
      h4 {
        font-size: 18px !important;
        line-height: 26px !important;
      }

      p {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
`;

export default MyComponent;
