import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import { Pagination,A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])

    console.log('dq', data)

    return (
        <StyledComponent className={'pb-120 '} offset={offset}>
            <Container fluid className={'ld-magazine'}>
                <Row>
                    <Col className={'ld-magazine__title'}>
                        <Title margin={'0 0 40px'} text={reactHtmlParser(data?.title)}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className={'ld-magazine__cover'}>
                        <div className={'img-wrap'}>
                            <Img src={data?.cover}/>
                        </div>
                    </Col>
                    <Col md={8} className={'ld-magazine__slide'}>
                        {
                            data?.list &&
                            <Swiper
                                // install Swiper modules
                                modules={[Pagination, A11y]}
                                spaceBetween={30}
                                // spaceBetween={0}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                            >
                                {
                                    data?.list?.length>0 &&
                                    data?.list?.map((e,i)=>{
                                        return(
                                            <SwiperSlide>
                                                <div className={'ld-magazine__slide__wrapper'} key={i}>
                                                    <Img src={e?.img}/>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-left: ${props => props.offset ? props.offset +  'px' : '90px'};
  .swiper-slide-next::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Apply the desired color as the overlay background */
    z-index: 2;
  }
  .swiper-pagination {
    padding-right: ${props => props.offset ? props.offset + 'px' : '90px'};
    text-align: end;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    .swiper-pagination-bullet{
      width: 12px;
      height: 12px;
      background-color: #FFFFFF;
      border: 1px solid #545456;
      &.swiper-pagination-bullet-active {
        background-color: #545456;
        border: 0 !important;
      }
    }
  }
  .swiper-slide{
    padding-bottom: 32px;
  }
  .swiper-button-prev{
    display: none;
  }
  .swiper-button-next{
    display: none;
  }

  .ld-magazine{
    &__cover{
      .img-wrap{
        position: relative;
        padding-top: calc(520 / 370 * 100%);
      }
    }
    &__slide{
      padding: 0 20px 20px 20px;
      &__wrapper{
        position: relative;
        padding-top: calc(520 / 770 * 100%);
      }
    }
  }

  .swiper-pagination-bullet{
    margin: 0!important;
  }

  @media(max-width: 767px){
    padding-left: 0 !important;
    .ld-magazine{
      &__slide{
        margin-top: 30px;
      }
    }
    .swiper-slide-active{
      margin-right: 0 !important;
    }
    .swiper-pagination{
      text-align: center;
      justify-content: center;
      padding-right: 0;
    }
  }
`;

export default MyComponent;
