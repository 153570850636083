import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {useEffect, useState} from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";

// page imports
import About from './pages/about';
import Expertise from './pages/expertise';
import Vendors from './pages/vendors';
import Contact from './pages/contact';
import Careers from './pages/careers';
import Components from './pages/components';
import Home from './pages/home';
import Project from './pages/project';
import ProjectDetail from './pages/project/single';
import Press from './pages/press';
import Certificate from './pages/certificate';
import Menu from "./components/Menu";
import {Container} from "react-bootstrap";
import Footer from "./components/Footer";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "./api/network/apiEndPoints";
import {fetchDatas} from "./api/redux/global";
import {ParallaxProvider} from "react-scroll-parallax";


function App() {

    gsap.registerPlugin(ScrollTrigger);
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL
        dispath(fetchDatas([api_url]))
    }, [])

    let getPost = useSelector(state => state.global)


    const footer = getPost?.posts[0];


    const location = useLocation();

    const [offset, setOffset] = useState()

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container').offsetLeft + 15)
            }
        })
    }, [location.pathname])

    useEffect(() => {
        if (location.pathname !== '/') {
            document.body.classList.add('in-inner-page')
        } else {
            document.body.classList.remove('in-inner-page')
        }

        window.scroll(0, 0)
    }, [location.pathname])


    return (
        <>
            <ToastContainer/>
            <Container/>
            <GlobalStyle/>
            <Menu offset={offset}/>
            <ParallaxProvider>
                <Switch location={location} key={'location.pathname'}>
                    <Route offset={offset} exact path="/"><Home offset={offset}/></Route>
                    <Route exact path="/components" component={Components}/>
                    <Route exact path="/about-us" component={About}/>
                    <Route exact path={`/projects`} component={Project}/>
                    <Route exact path={`/project/:slug`} component={ProjectDetail}/>
                    <Route exact path={`/expertise`} component={Expertise}/>
                    <Route exact path={`/vendors`} component={Vendors}/>
                    <Route exact path={`/contact`} component={Contact}/>
                    <Route exact path={`/careers`} component={Careers}/>
                    <Route exact path={`/press`} component={Press}/>
                    <Route exact path={`/certificate`} component={Certificate}/>
                    <Route component={Error}/>
                </Switch>
            </ParallaxProvider>
            <Footer offset={offset} data={footer}/>
        </>


    );
}

export default App;
