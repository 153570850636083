import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'counter pt-120'}>
            <Container>
                <Row>
                    <VisibilitySensor offset={{top: -150}}>
                        <>
                            {data && data?.length > 0 && data?.map((e, i) => {
                                return (
                                    <Col sm={4} className={'text-center'} key={i}>
                                        <h4>
                                            <CountUp
                                                start={0} end={e?.title} decimals={0}>
                                                {({countUpRef, start}) => (
                                                    <VisibilitySensor onChange={start}
                                                                      offset={{top: -50}}
                                                                      delayedCall>
                                                        <span ref={countUpRef}/>
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                            {e?.tag}
                                        </h4>
                                        <p>{e?.subtitle}</p>
                                    </Col>
                                );
                            })}
                            {/*<Col sm={4} className={'text-center'}>*/}
                            {/*    <h4> $<CountUp*/}
                            {/*        start={0} end={8.0} decimals={1}>*/}
                            {/*        {({countUpRef, start}) => (*/}
                            {/*            <VisibilitySensor onChange={start}*/}
                            {/*                              offset={{top: -50}}*/}
                            {/*                              delayedCall>*/}
                            {/*                <span ref={countUpRef}/>*/}
                            {/*            </VisibilitySensor>*/}
                            {/*        )}*/}
                            {/*    </CountUp> Billion</h4>*/}
                            {/*    <p>Portfolio Value</p>*/}
                            {/*</Col>*/}
                            {/*<Col sm={4} className={'text-center'}>*/}
                            {/*    <h4><CountUp*/}
                            {/*        start={0} end={4.5} decimals={1}>*/}
                            {/*        {({countUpRef, start}) => (*/}
                            {/*            <VisibilitySensor onChange={start}*/}
                            {/*                              offset={{top: -50}}*/}
                            {/*                              delayedCall>*/}
                            {/*                <span ref={countUpRef}/>*/}
                            {/*            </VisibilitySensor>*/}
                            {/*        )}*/}
                            {/*    </CountUp> million</h4>*/}
                            {/*    <p>SQ. FT. OF PORTFOLIO</p>*/}
                            {/*</Col>*/}
                            {/*<Col sm={4} className={'text-center'}>*/}
                            {/*    <h4><CountUp*/}
                            {/*        start={0} end={2.0} decimals={1}>*/}
                            {/*        {({countUpRef, start}) => (*/}
                            {/*            <VisibilitySensor onChange={start}*/}
                            {/*                              offset={{top: -50}}*/}
                            {/*                              delayedCall>*/}
                            {/*                <span ref={countUpRef}/>*/}
                            {/*            </VisibilitySensor>*/}
                            {/*        )}*/}
                            {/*    </CountUp> million</h4>*/}
                            {/*    <p>SQ. FT. IN DEVELOPMENT</p>*/}
                            {/*</Col>*/}
                        </>

                    </VisibilitySensor>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .row{
    justify-content: center;
  }
  .col-sm-4 {
    &:not(:nth-last-of-type(1)) {
      border-right: 1px solid rgba(84, 84, 86, 0.3);
    }
  }

  h4 {
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    color: #212121;
    text-transform: uppercase;
  }

  p {
    text-transform: uppercase;
    color: #212121;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    h4 {
      font-size: 32px;
      line-height: 50px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media (max-width: 767px) {
    .col-sm-4 {
      min-width: 100%;
      border: none;
      padding-bottom: 75px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 50px;
        width: 1px;
        background-color: rgba(84, 84, 86, 0.3);
        left: 0;
        right: 0;
        margin: auto;
        bottom: 10px;
      }

      &:nth-last-of-type(1) {
        padding-bottom: 0;

        &:after {
          display: none;
        }
      }
    }
  }
`;

export default MyComponent;
