import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/project";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import TextImg from "../../components/TextImg";
import ProjectSingle from "../../components/ProjectSingle";
import ProjectList from "../../components/project/ProjectList";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Sectors from "../../components/project/Sectors";

const MyComponent = () => {

    gsap.registerPlugin(ScrollTrigger);
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECTS
        dispath(fetchPosts([api_url]))
    }, [])

    let getPost = useSelector(state => state.project)

    const banner = getPost?.posts?.[0]?.banner;
    const together = getPost?.posts?.[0]?.together;
    const project = getPost?.posts?.[0]?.projects;
    const sector = getPost?.posts?.[0]?.sector;

    const title = 'Together, we harness the power of Landscape.'
    const dest = 'We work in partnership with leading clientele across various fields to help them define and resolve issues through a highly inclusive, innovative, & interdisciplinary process. We deeply understand diffrent sectors and how design can empower their business success.\n '

    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [getPost])

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Projects | Saudi Landscape</title>
                <meta name="description" content="Design | Build | O&M"/>
            </Helmet>
            {getPost?.loading && <Loading/>}

            <StyledComponent>
                <InnerBanner text={banner?.subtitle} img={banner?.image}
                             title={banner?.title}/>
                <TextImg img={together?.image} title={together?.title} desc={together?.description}/>
                <Sectors data={sector}/>
                <ProjectList data={project}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  p {
    -webkit-line-clamp: initial;
  }
`;

export default MyComponent;
