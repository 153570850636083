import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'why-landscape pb-120'}>
            <Container>
                <Row>
                    <Col>
                        <Title margin={'0 0 40px 0'} text={data?.title}/>
                        <p className={'fade-up'}>{data?.description}</p>

                        <ul className={'fade-up'}>
                            {
                                data?.list && data?.list?.length > 0 &&
                                data?.list?.map((e, i) => {
                                    return (
                                        <li key={i}><img src="/images/static/correct.svg" alt=""/> {e?.title}</li>
                                    );
                                })
                            }
                        </ul>

                        <div className="why-landscape__img">
                            <div className="why-landscape__img__img fade-up">
                                <Img src={data?.image}/>
                            </div>
                        </div>

                    </Col>
                </Row>


            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  p {
    //max-width: 80%;
    font-size: 20px;
    line-height: 32px;
  }

  ul {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 60px;

    li {
      width: calc(50% - 80px);
      padding: 30px 0;
      border-bottom: 1px solid #E9E9E9;
      display: flex;
      //align-items: center;
      text-transform: uppercase;
      font-weight: 400;

      &:nth-of-type(1) {
        border-top: 1px solid #E9E9E9;
      }

      &:nth-of-type(2) {
        border-top: 1px solid #E9E9E9;
      }

      img {
        margin-right: 30px;
        height: 40px;
      }
    }
  }

  .why-landscape__img {
    width: 100%;

    &__img {
      width: 100%;
      padding-top: calc(500 / 1170 * 100%);
      position: relative;
      background-color: rgba(221, 221, 221, 0.7);
    }
  }

  @media (max-width: 991px) {
    p {
      //min-width: 100%;
      font-size: 18px;
      line-height: 28px;
    }

    ul li {
      width: 100%;
      font-size: 18px;
      line-height: 26px;

      img {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 500px) {
    .why-landscape__img__img {
      padding-top: 0;
      height: 220px;
    }
  }

`;

export default MyComponent;
