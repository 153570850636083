import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import TextImg from "../../components/career/TextImg";
import Inquires from "../../components/career/Inquires";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/career";
import {Loading} from "../../components/Loading";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyComponent = () => {
    gsap.registerPlugin(ScrollTrigger);
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAREER
        dispath(fetchData([api_url]))
    }, [])

    let getPost = useSelector(state => state.career)


    const banner = getPost?.posts?.[0]?.banner;
    const opportunity = getPost?.posts?.[0]?.opportunity;


    const desc = "We are committed to promotion and development through leadership programs and transversality.\n" +
        "\n" +
        "<br/> <br/> If you are an ambitious person wishing to share your knowledge and experience and to continue to grow with us, \n" +
        "you can find the right opportunity at Saudi Landscape co."


    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [getPost])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Careers | Saudi Landscape</title>
                <meta name="description" content="Design | Build | O&M"/>
            </Helmet>
            {getPost?.loading && <Loading/>}
            <StyledComponent className={'careers'}>
                <InnerBanner text={banner?.subtitle} title={banner?.title}
                             img={banner?.image}/>
                <TextImg title={opportunity?.title} quotes={opportunity?.quote}
                         desc={opportunity?.description} img={opportunity?.image}/>

                <Inquires/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
