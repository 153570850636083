import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Container} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {ParallaxBanner, ParallaxBannerLayer} from "react-scroll-parallax";

const InnerBanner = ({img, text, title, noAnim}) => {
    return (
        <StyledInnerBanner className='inner-banner'>
            <div className="inner-banner__parallax__img">
                <Img banner={true} src={img}/>
                <Container>
                    <div className="inner-banner__content">
                        <h2>{reactHtmlParser(title)}</h2>
                        <p>{text}</p>
                    </div>

                </Container>
            </div>

        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(420 / 1366 * 100%);
  position: relative;
  background-color: #DDD;

  .inner-banner__parallax {
    position: absolute !important;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;

    &__img {
      &:after {
        content: '';
        position: absolute;
        //background-color: rgba(0, 0, 0, 0.25);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 68%);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;

      }
    }


    .container {
      z-index: 99999;
      position: absolute;
    }
  }

  @media (max-width: 768px) {
    &:after {
      content: '';
      position: absolute;
      //background-color: rgba(0, 0, 0, 0.25);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 68%);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;

    }
  }


  .container {
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: 2;
    //padding-left: 15px;
  }

  .inner-banner__content {
    width: 50%;

    h2 {
      font-size: 60px;
      font-weight: 400;
      line-height: 70px;
      text-transform: capitalize;
      z-index: 2;
      color: #ffffff;
      margin-bottom: 10px;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  @media (max-width: 991px) {
    padding-top: calc(240 / 414 * 100%);
    .container {
      bottom: 40px;
    }

    .inner-banner__content {
      width: 100%;

      h2 {
        font-size: 36px;
        line-height: 48px;
      }

      p {
        font-size: 18px;
        line-height: 20px;

      }
    }
  }

`;

export default InnerBanner;
