import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover} from "../styles/globalStyleVars";
import Expertise from "../pages/expertise";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({offset, data}) => {

    return (
        <StyledComponent className={'main-footer'}>
            <Container fluid style={{paddingLeft: offset + 'px'}}>
                <Row>
                    <Col sm={2} className={'main-footer__logo-area pr-0'}>
                        <img src="/images/static/logo-black.svg" alt=""/>
                        {/*<p>Takhasousi Road | Building 7060 | First Floor | Offices #104 & 106 | Mohamadia | Riyadh,*/}
                        {/*    KSA</p>*/}
                        <p>{reactHtmlParser(data?.address)}</p>
                    </Col>

                    <Col sm={{span: 9, offset: 1}} style={{paddingRight: offset + 'px', backgroundColor: '#F9F9F9'}}>

                        <div className="main-footer__menu">
                            <div className="main-footer__menu__inner">
                                <ul>
                                    <li><Link to={'/'}>Home</Link></li>
                                    <li><Link to={'/about-us'}>About us</Link></li>
                                    <li><Link to={'/expertise'}>Expertise</Link></li>
                                    <li><Link to={'/projects'}>Projects</Link></li>
                                    <li><Link to={'/vendors'}>Vendors</Link></li>
                                    <li><Link to={'/careers'}>Career</Link></li>
                                    <li><Link to={'/contact'}>Contact us</Link></li>
                                </ul>

                                <ul className={'social'}>
                                    <li><a href={data?.facebook}>
                                        <svg id="facebook-logo-in-circular-button-outlined-social-symbol"
                                             xmlns="http://www.w3.org/2000/svg" width="8.402" height="18"
                                             viewBox="0 0 8.402 18">
                                            <g id="Group_21" data-name="Group 21">
                                                <path id="Path_6" data-name="Path 6"
                                                      d="M228.617,147.856v-9.012h2.974l.47-2.986h-3.444v-1.5c0-.779.256-1.523,1.374-1.523h2.236v-2.981h-3.174c-2.669,0-3.4,1.757-3.4,4.193v1.807h-1.831v2.988h1.831v7.886h2.961Z"
                                                      transform="translate(-223.825 -129.856)" fill="#212121"/>
                                            </g>
                                        </svg>
                                    </a></li>

                                    <li><a href={data?.linkedin}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.834" height="18"
                                             viewBox="0 0 18.834 18">
                                            <g id="Group_28" data-name="Group 28" transform="translate(0 0)">
                                                <rect id="Rectangle_14" data-name="Rectangle 14" width="4.046"
                                                      height="12.151"
                                                      transform="translate(0.233 5.849)" fill="#212121"/>
                                                <path id="Path_9" data-name="Path 9"
                                                      d="M162.645,140.723a4.355,4.355,0,0,0-3.372-1.392,4.86,4.86,0,0,0-1.4.19,3.186,3.186,0,0,0-1.067.533,4.568,4.568,0,0,0-1.171,1.286v-1.728H151.6l.012.589q.012.589.012,3.629t-.025,7.933h4.034v-6.781a2.961,2.961,0,0,1,.135-.993,2.6,2.6,0,0,1,.779-1.048,1.985,1.985,0,0,1,1.294-.423,1.745,1.745,0,0,1,1.551.73,3.548,3.548,0,0,1,.5,2.017v6.5h4.034V144.8A5.838,5.838,0,0,0,162.645,140.723Z"
                                                      transform="translate(-145.086 -133.764)" fill="#212121"/>
                                                <path id="Path_10" data-name="Path 10"
                                                      d="M2.281,9.708A2.308,2.308,0,0,0,.631,10.3,1.974,1.974,0,0,0,0,11.8a2.009,2.009,0,0,0,.613,1.5,2.213,2.213,0,0,0,1.618.6h.025a2.31,2.31,0,0,0,1.662-.6,1.941,1.941,0,0,0,.619-1.5,2.045,2.045,0,0,0-.625-1.5A2.242,2.242,0,0,0,2.281,9.708Z"
                                                      transform="translate(0 -9.708)" fill="#212121"/>
                                            </g>
                                        </svg>
                                    </a></li>

                                    <li><a href={data?.twitter}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.688" height="16" viewBox="0 0 19.688 16">
                                            <path id="Icon_metro-twitter" data-name="Icon metro-twitter" d="M22.259,6.711a8.076,8.076,0,0,1-2.32.636,4.051,4.051,0,0,0,1.776-2.235,8.086,8.086,0,0,1-2.565.98,4.043,4.043,0,0,0-6.883,3.684,11.467,11.467,0,0,1-8.325-4.22,4.044,4.044,0,0,0,1.25,5.393,4.023,4.023,0,0,1-1.829-.505c0,.017,0,.034,0,.051A4.042,4.042,0,0,0,6.6,14.456a4.046,4.046,0,0,1-1.824.069A4.043,4.043,0,0,0,8.551,17.33a8.1,8.1,0,0,1-5.017,1.729A8.192,8.192,0,0,1,2.571,19a11.433,11.433,0,0,0,6.192,1.815A11.414,11.414,0,0,0,20.255,9.325q0-.263-.012-.523a8.206,8.206,0,0,0,2.016-2.091Z" transform="translate(-2.571 -4.817)"/>
                                        </svg>
                                    </a></li>

                                    <li><a href={data?.instagram}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                             viewBox="0 0 18 18">
                                            <g id="instagram" transform="translate(0 0)">
                                                <g id="Group_23" data-name="Group 23" transform="translate(0 0)">
                                                    <g id="Group_22" data-name="Group 22">
                                                        <path id="Path_7" data-name="Path 7"
                                                              d="M12.375,0H5.625A5.626,5.626,0,0,0,0,5.625v6.75A5.626,5.626,0,0,0,5.625,18h6.75A5.626,5.626,0,0,0,18,12.375V5.625A5.626,5.626,0,0,0,12.375,0Zm3.937,12.375a3.942,3.942,0,0,1-3.937,3.937H5.625a3.942,3.942,0,0,1-3.937-3.937V5.625A3.942,3.942,0,0,1,5.625,1.688h6.75a3.942,3.942,0,0,1,3.937,3.938Z"
                                                              fill="#212121"/>
                                                    </g>
                                                </g>
                                                <g id="Group_25" data-name="Group 25"
                                                   transform="translate(5.058 5.058)">
                                                    <g id="Group_24" data-name="Group 24">
                                                        <path id="Path_8" data-name="Path 8"
                                                              d="M131.942,128a3.942,3.942,0,1,0,3.942,3.942A3.942,3.942,0,0,0,131.942,128Zm0,6.406a2.464,2.464,0,1,1,2.464-2.464A2.467,2.467,0,0,1,131.942,134.406Z"
                                                              transform="translate(-128 -128)" fill="#212121"/>
                                                    </g>
                                                </g>
                                                <g id="Group_27" data-name="Group 27"
                                                   transform="translate(12.429 3.475)">
                                                    <g id="Group_26" data-name="Group 26">
                                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.048"
                                                                cy="1.048"
                                                                r="1.048" fill="#212121"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a></li>
                                </ul>
                            </div>

                        </div>

                        <div className="touch">
                            <p>Get in touch:</p>
                            <ul>
                                <li><a target={'_blank'} href={data?.whatsapp}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <path id="Icon_simple-whatsapp" data-name="Icon simple-whatsapp"
                                              d="M11.665,9.588c-.2-.1-1.178-.578-1.36-.644s-.315-.1-.449.1-.514.643-.629.775-.233.14-.431.05a5.411,5.411,0,0,1-1.6-.99A6.048,6.048,0,0,1,6.088,7.5c-.116-.2-.013-.31.087-.41s.2-.23.3-.349a1.69,1.69,0,0,0,.2-.331.366.366,0,0,0-.017-.349c-.05-.1-.448-1.08-.615-1.471s-.325-.34-.448-.34-.247-.01-.381-.01a.752.752,0,0,0-.531.239,2.226,2.226,0,0,0-.7,1.65A3.9,3.9,0,0,0,4.8,8.179a8.889,8.889,0,0,0,3.4,2.99c.476.2.847.32,1.136.419a2.758,2.758,0,0,0,1.253.081,2.052,2.052,0,0,0,1.344-.951,1.65,1.65,0,0,0,.12-.95c-.049-.09-.18-.14-.38-.23M8.041,14.5H8.03a6.669,6.669,0,0,1-3.37-.92l-.24-.143-2.5.65.67-2.43-.159-.25a6.612,6.612,0,0,1,10.3-8.157,6.525,6.525,0,0,1,1.939,4.66A6.62,6.62,0,0,1,8.045,14.5M13.68,2.3A8.1,8.1,0,0,0,8.03,0a7.926,7.926,0,0,0-6.9,11.892L0,16l4.223-1.1a8.041,8.041,0,0,0,3.807.965h0A7.96,7.96,0,0,0,16,7.933a7.863,7.863,0,0,0-2.33-5.607"
                                              transform="translate(0 0)" fill="#212121"/>
                                    </svg>
                                    whatsapp
                                </a></li>

                                <li><a target={'_blank'} href={`tel:${data?.phone}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.02"
                                         viewBox="0 0 16 16.02">
                                        <path id="Icon_zocial-call" data-name="Icon zocial-call"
                                              d="M3.3,6.09a1.179,1.179,0,0,1,.3-.64L6,3.05q.28-.24.42.08L8.362,6.77a.524.524,0,0,1-.1.62l-.88.88a1,1,0,0,0-.28.62,3.054,3.054,0,0,0,.62,1.54,13.153,13.153,0,0,0,1.22,1.6l.62.639c.187.187.426.417.721.69a12.076,12.076,0,0,0,1.45,1.08,3.245,3.245,0,0,0,1.59.67.871.871,0,0,0,.64-.26L15,13.811a.44.44,0,0,1,.6-.08l3.5,2.06a.292.292,0,0,1,.16.21.255.255,0,0,1-.08.23l-2.4,2.4a1.176,1.176,0,0,1-.639.3,4.839,4.839,0,0,1-2.211-.27,10,10,0,0,1-2.28-1.09q-1.05-.68-1.95-1.38t-1.44-1.2l-.52-.5q-.2-.2-.53-.55t-1.151-1.38a20.089,20.089,0,0,1-1.42-2.01A11.727,11.727,0,0,1,3.6,8.321,4.745,4.745,0,0,1,3.3,6.09Z"
                                              transform="translate(-3.267 -2.947)" fill="#212121"/>
                                    </svg>
                                    {data?.phone}
                                </a>
                                </li>

                                <li><a target={'_blank'} href={`mailto:${data?.email}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10.8"
                                         viewBox="0 0 16 10.8">
                                        <path id="Icon_zocial-email" data-name="Icon zocial-email"
                                              d="M.032,11.652V2.765q0-.015.046-.293l5.23,4.474L.094,11.96a1.308,1.308,0,0,1-.062-.309Zm.694-9.8a.665.665,0,0,1,.262-.046H15.075a.874.874,0,0,1,.278.046l-5.246,4.49L9.413,6.9,8.04,8.026,6.667,6.9l-.694-.555ZM.742,12.562,6,7.517,8.04,9.168l2.037-1.651,5.261,5.045a.741.741,0,0,1-.262.046H.989a.7.7,0,0,1-.247-.046ZM10.771,6.946l5.215-4.474a.921.921,0,0,1,.046.293v8.887a1.183,1.183,0,0,1-.046.309Z"
                                              transform="translate(-0.032 -1.808)" fill="#212121"/>
                                    </svg>
                                    {data?.email}
                                </a>
                                </li>
                            </ul>
                        </div>

                        <div className="copyright">
                            <p>© {new Date().getFullYear()} Saudi Landscape. All rights reserved.</p>
                            <a href={data?.profile}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.416" height="12.648"
                                     viewBox="0 0 10.416 12.648">
                                    <path id="Icon_metro-file-download" data-name="Icon metro-file-download"
                                          d="M19.413,10.891H16.437V6.427H11.973v4.464H9L14.205,16.1ZM9,17.586v1.488H19.413V17.586H9Z"
                                          transform="translate(-8.997 -6.427)" fill="#212121"/>
                                </svg>
                                Company Profile</a>
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  //padding-bottom: 40px;
  //border-top: 1px solid #E9E9E9;
  background-color: rgb(249, 249, 249);

  .main-footer__logo-area {
    padding-top: 60px;

    p {
      margin-top: 35px;
      //margin-top: 65px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #545456;
    }
  }

  .main-footer__menu {
    //padding-left: 40px;
    padding-top: 50px;
    position: relative;

    &__inner {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E9E9E9;
      padding-bottom: 20px;
    }


    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        a {
          font-size: 14px;
          line-height: 30px;
          font-weight: 400;
          text-transform: capitalize;
          letter-spacing: 1px;
          white-space: nowrap;
        }

        &:not(:nth-last-of-type(1)) a {
          margin-right: 27px;
        }
      }

      &.social {
        flex-wrap: nowrap;

        path, rect, circle {
          transition: all .4s ease;
        }

        li {
          &:not(:nth-last-of-type(1)) a {
            margin-right: 15px;
          }

          a:hover {
            path, rect, circle {
              fill: ${hover}
            }
          }
        }
      }
    }
  }

  .touch {
    //padding-left: 40px;
    margin-top: 40px;

    p {
      font-size: 14px;
      line-height: 30px;
      font-weight: 400;
      //margin-bottom: 20px;
    }

    ul {
      display: flex;

      li {
        a {
          font-size: 12px;
          line-height: 30px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
          }
        }

        &:not(:nth-last-of-type(1)) a {
          margin-right: 50px;
        }
      }
    }
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    margin-top: 65px;
    //padding-left: 40px;
    margin-bottom: 40px;

    p {
      display: flex;
      font-size: 12px;
      line-height: 18px;
    }

    a {
      color: #212121;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;

      svg {
        margin-right: 8px;
      }
    }
  }

  a {
    path {
      transition: .4s ease;
    }

    &:hover {
      path {
        fill: ${hover};
      }
    }
  }

  @media (max-width: 767px) {

    .container-fluid {
      padding-left: 15px !important;
    }


    .col-sm-2, .col-sm-9 {
      min-width: 100%;
      margin: 0;
    }

    .main-footer__logo-area {
      display: flex;
      justify-content: space-between;
      padding-right: 15px !important;
      padding-bottom: 25px;
      flex-wrap: wrap;
      padding-top: 50px;

      img {
        margin-bottom: 30px;
      }

      p {
        width: 100%;
        margin-top: 0;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .main-footer__menu {
      padding-top: 0;
    }

    .main-footer__menu, .touch, .copyright {
      padding-left: 0;
    }

    .main-footer__menu__inner {
      flex-wrap: wrap;

      ul {
        width: 100%;

        li {
          width: 50%;
          margin-bottom: 8px;

          a {
            font-size: 16px;
          }
        }

        &.social {
          margin-top: 25px;
          //padding-bottom: 20px;

          li {
            width: fit-content;
          }
        }
      }
    }

    .touch {
      margin-top: 30px;

      p {
        margin-bottom: 8px;
      }

      ul {
        flex-wrap: wrap;

        li {
          width: 50%;
          margin-bottom: 10px;

          &:nth-last-of-type(1) {
            width: 100%;
            margin-bottom: 0;
          }
          &:not(:nth-last-of-type(1)) a {
            margin-right: 0px !important;
          }
        }
      }
    }

    .copyright {
      margin-top: 25px;
      padding-top: 30px;
      border-top: 1px solid #E9E9E9;
      flex-direction: column-reverse;
      margin-bottom: 20px;

      a {
        margin-bottom: 30px;
      }
    }

  }

`;

export default MyComponent;
