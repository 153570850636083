import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import TextImg from "../../components/TextImg";
import Why from "../../components/about/Why";
import {fetchData} from "../../api/redux/about";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {Loading} from "../../components/Loading";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


const MyComponent = () => {

    const dispath = useDispatch()
    gsap.registerPlugin(ScrollTrigger);
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchData([api_url]))
    }, [])

    let getPost = useSelector(state => state.about)


    const banner = getPost?.posts?.[0]?.banner;
    const about = getPost?.posts?.[0]?.about;
    const why = getPost?.posts?.[0]?.landscape;

    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [getPost])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About us | Saudi Landscape</title>
                <meta name="description" content="Design | Build | O&M"/>
            </Helmet>

            {getPost?.loading && <Loading/>}
            <StyledComponent className={'about-us'}>
                <InnerBanner noAnim title={banner?.title} text={banner?.subtitle}
                             img={banner?.image}/>
                <TextImg textOverflow={false} img={about?.image} title={about?.title}
                         desc={about?.description}
                         subTitle={about?.subtitle}/>

                <Why data={why}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  p {
    -webkit-line-clamp: initial;
  }
`;

export default MyComponent;
