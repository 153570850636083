import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Title from "../Title";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postData} from "../../api/redux/home";

const MyComponent = ({data}) => {
    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.CONTACT_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.number);
        formData.append('your-message', e?.message);
        dispatch(postData([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (isSubmitSuccessful) {
            success('Thank you for your message')
            reset()
        }
    }, [isSubmitSuccessful])


    return (
        <StyledComponent className={'inquires pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={4}>
                        <Title margin={'0 0 40px 0'} text={data?.title}/>
                        <p className={'fade-up'}>{reactHtmlParser(data?.desc)}</p>
                        <ul>
                            <li><a target={'_blank'} href={`callto:${reactHtmlParser(data?.phone.toLowerCase())}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.02"
                                     viewBox="0 0 16 16.02">
                                    <path id="Icon_zocial-call" data-name="Icon zocial-call"
                                          d="M3.3,6.09a1.179,1.179,0,0,1,.3-.64L6,3.05q.28-.24.42.08L8.362,6.77a.524.524,0,0,1-.1.62l-.88.88a1,1,0,0,0-.28.62,3.054,3.054,0,0,0,.62,1.54,13.153,13.153,0,0,0,1.22,1.6l.62.639c.187.187.426.417.721.69a12.076,12.076,0,0,0,1.45,1.08,3.245,3.245,0,0,0,1.59.67.871.871,0,0,0,.64-.26L15,13.811a.44.44,0,0,1,.6-.08l3.5,2.06a.292.292,0,0,1,.16.21.255.255,0,0,1-.08.23l-2.4,2.4a1.176,1.176,0,0,1-.639.3,4.839,4.839,0,0,1-2.211-.27,10,10,0,0,1-2.28-1.09q-1.05-.68-1.95-1.38t-1.44-1.2l-.52-.5q-.2-.2-.53-.55t-1.151-1.38a20.089,20.089,0,0,1-1.42-2.01A11.727,11.727,0,0,1,3.6,8.321,4.745,4.745,0,0,1,3.3,6.09Z"
                                          transform="translate(-3.267 -2.947)" fill="#212121"/>
                                </svg>
                                {reactHtmlParser(data?.phone)}
                            </a>
                            </li>

                            <li><a target={'_blank'} href={`mailto:${reactHtmlParser(data?.email.toLowerCase())}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10.8"
                                     viewBox="0 0 16 10.8">
                                    <path id="Icon_zocial-email" data-name="Icon zocial-email"
                                          d="M.032,11.652V2.765q0-.015.046-.293l5.23,4.474L.094,11.96a1.308,1.308,0,0,1-.062-.309Zm.694-9.8a.665.665,0,0,1,.262-.046H15.075a.874.874,0,0,1,.278.046l-5.246,4.49L9.413,6.9,8.04,8.026,6.667,6.9l-.694-.555ZM.742,12.562,6,7.517,8.04,9.168l2.037-1.651,5.261,5.045a.741.741,0,0,1-.262.046H.989a.7.7,0,0,1-.247-.046ZM10.771,6.946l5.215-4.474a.921.921,0,0,1,.046.293v8.887a1.183,1.183,0,0,1-.046.309Z"
                                          transform="translate(-0.032 -1.808)" fill="#212121"/>
                                </svg>
                                {reactHtmlParser(data?.email.toLowerCase())}
                            </a>
                            </li>
                        </ul>
                    </Col>

                    <Col sm={{span: 6, offset: 2}}>
                        <Form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    }

                                })} type="text" placeholder="Full name *"/>
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </Form.Group>
                            {/*<Form.Group>*/}
                            {/*    <Form.Control {...register('business', {*/}
                            {/*        required: {*/}
                            {/*            value: true,*/}
                            {/*            message: "Please enter your Business name",*/}
                            {/*        }*/}

                            {/*    })} type="text" placeholder="Business name *"/>*/}
                            {/*    <p className={'form-error'}>{errors.business?.message}</p>*/}
                            {/*</Form.Group>*/}
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('email', {
                                    required: {
                                        value: true,
                                        message: "Please enter a valid email address"
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })} type="email" placeholder="Email *"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('number')} type="number" placeholder="Phone number"/>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('message')} type="text" placeholder="Message"/>
                            </Form.Group>

                            {errors.email || errors.name ?
                                <div className="button-wrap justify-content-end d-flex disabled">
                                    <Button margin={'30px 0 0 0'} text={'Send'}/>
                                </div>
                                :
                                <div className="button-wrap justify-content-end d-flex"
                                     onClick={handleSubmit(handleFormSubmit)}>
                                    <Button margin={'30px 0 0 0'} text={'Send'}/>
                                </div>

                            }

                        </Form>
                    </Col>


                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  h4 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    color: #212121;
  }

  ul{
    margin-top: 20px;
    li{
      a{
        font-weight: 500;
      }
      svg{
        margin-right: 20px;
      }
    }
  }

  p {
    span {
      font-weight: 600;
    }
  }

  .form-control {
    border: none;
    border-bottom: 1px solid rgba(194, 194, 194, 0.3);
    border-radius: 0;
    padding: 20px 20px 24px 0;
    margin-bottom: 20px;

    ::placeholder {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      color: #a4a4a4;
    }
  }

  textarea {
    min-height: 90px;
    max-height: 100px;
  }

  @media (max-width: 575px) {
    form {
      margin-top: 60px;
    }
  }
`;

export default MyComponent;
