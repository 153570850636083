import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import expertiseReducer from '../redux/expertise'
import vendorReducer from "../redux/vendor"
import careerReducer from "../redux/career"
import contactReducer from "../redux/contact"
import projectReducer from  "../redux/project"
import globalReducer from  "../redux/global"
import pressReducer from "../redux/press"
import certficateReducer from "../redux/certificate"


const store = configureStore({
    reducer: {
        posts: postReducer,
        home: homeReducer,
        about: aboutReducer,
        expertise:expertiseReducer,
        vendor:vendorReducer,
        career: careerReducer,
        contact: contactReducer,
        project: projectReducer,
        global: globalReducer,
        press: pressReducer,
        certificate: certficateReducer,

    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

