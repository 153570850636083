import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {ParallaxBanner, ParallaxBannerLayer} from "react-scroll-parallax";

const MyComponent = ({data}) => {

    useEffect(() => {
        if (window.innerWidth < 769) {
            const currentHeight = window.innerHeight;
            document.querySelector('.home-banner').style.height = `${currentHeight}px`
            document.querySelector('.home-banner .container').style.height = `${currentHeight}px`
        }

    }, [])

    return (
        <StyledComponent className={'home-banner'}>
            <Img src={data?.video}/>
            <Container>
                <div className="home-banner__content">
                    <h4>{reactHtmlParser(data?.subtitle)}</h4>
                    <h2>{reactHtmlParser(data?.title)}</h2>
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh;
  position: relative;

  .home-banner__parallax {
    height: 100vh;
  }

  &:after {
    content: '';
    position: absolute;
    //background: linear-gradient(0deg, rgba(255, 255, 255, 0) 17%, rgb(0 0 0 / 60%) 93%);
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    //background: linear-gradient(0deg, rgba(255, 255, 255, 0) 17%, rgba(0, 0, 0, 0.66) 93%);
    z-index: 2;
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    height: 100vh;
  }

  .home-banner__content {
    position: absolute;
    bottom: 100px;
    left: 15px;
    z-index: 5;

    h4 {
      color: rgba(255, 255, 255, 0.8);
      font-size: 34px;
      line-height: 70px;
    }

    h2 {
      font-size: 60px;
      font-weight: 400;
      line-height: 70px;
      color: #E9E9E9;
    }
  }

  @media (max-width: 767px) {
    //height: -webkit-fill-available;
    .home-banner__content {
      bottom: 60px;

      h4 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 5px;
      }

      h2 {
        font-size: 36px;
        line-height: 48px;
      }
    }
  }
`;

export default MyComponent;
