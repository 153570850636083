import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import Title from '../Title';
import gsap from 'gsap';

const MyComponent = () => {
    const data = [
        {
            title: 'Irrigation',
            items: ['Irrigation System', 'Watering Schedule', 'Sprinklers'],
        },
        {
            title: 'Softscape',
            items: ['Plants', 'Flowers', 'Garden Design'],
        },
        {
            title: 'Hardscape',
            items: ['Patio', 'Walkways', 'Retaining Walls'],
        },
        {
            title: "Water Features",
            items: ['Irrigation System', 'Watering Schedule', 'Sprinklers'],
        },
        {
            title: "Landscape Structure",
            items: ['Patio', 'Walkways', 'Retaining Walls'],
        },
        {
            title: "Furniture",
            items: ['Irrigation System', 'Watering Schedule', 'Sprinklers'],
        },
        {
            title: "Light & Sound",
            items: ['Patio', 'Walkways', 'Retaining Walls'],
        },
        {
            title: "Outdoor Art",
            items: ['Irrigation System', 'Watering Schedule', 'Sprinklers'],
        },
        {
            title: "Agriculture",
            items: ['Patio', 'Walkways', 'Retaining Walls'],
        }
    ];

    const [selectedTab, setSelectedTab] = useState(null);

    const handleTabClick = (index) => {
        if (selectedTab === index) {
            setSelectedTab(null);
        } else {
            setSelectedTab(index);
        }
    };

    useEffect(() => {
        const animateListItems = (items) => {
            gsap.from(items, {
                opacity: 0,
                y: 20,
                duration: 0.5,
                stagger: 0.2, // Adjust the stagger time for the desired effect
            });
        };

        const tabElements = document.querySelectorAll('.tab__single');

        // Add hover event listeners to tab elements
        tabElements.forEach((tabElement, index) => {
            tabElement.addEventListener('mouseenter', () => {
                animateListItems(tabElement.querySelectorAll('li'));
            });
        });

        if (selectedTab !== null) {
            const liItems = document.querySelectorAll('.selected-tab-container ul li');
            animateListItems(liItems);
        }
    }, [selectedTab]);

    return (
        <StyledComponent className={'pb-120'}>
            <Container>
                <Title text={'Landscape Elements'} margin={'0 0 40px'} />
                <div className={'tab'}>
                    {data.map((item, index) => (
                        <div
                            className={`tab__single ${selectedTab === index ? 'active' : ''}`}
                            key={index}
                            onClick={() => handleTabClick(index)}
                        >
                            <div className={'tab__single__title'}>
                                <h5>{item.title}</h5>
                            </div>
                            <ul>
                                {item.items.map((liItem, liIndex) => (
                                    <li key={liIndex}>{liItem}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </Container>
            {selectedTab !== null && (
                <Container>
                    <div className="selected-tab-container">
                        <ul>
                            {data[selectedTab].items.map((liItem, liIndex) => (
                                <li key={liIndex}>{liItem}</li>
                            ))}
                        </ul>
                    </div>
                </Container>
            )}
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .tab {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    //grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    //display: flex;
    //flex-wrap: wrap;
    &__single {
      //flex: 0 1 calc(20% - 20px);
      display: flex;
      flex-direction: column;
      height: auto;
      width: 235px;
      padding: 0;
      margin-bottom: 30px;
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 100%;
        border-right: 1px solid rgb(203, 203, 204);
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.16);
        h5 {
          text-align: center;
          width: 65%;
          font-size: 20px;
          line-height: 26px;
          font-weight: 500;
          color: #212121;
        }
      }
      ul {
        display: none;
        background: transparent;
        transition: all 0.3s ease;
        li{
          font-size: 20px;
          line-height: 26px;
          font-weight: 400;
          margin-bottom: 15px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      &:hover {
        background: #BADA55;
        h5{
          color: #FFFFFF;
        }
        ul {
          padding-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #ffffff;
        }
      }
    }
    @media(max-width: 767px){
      grid-template-columns: repeat(2, 1fr);
      &__single{
        margin-bottom: 0;
        border: 0.5px solid rgb(203, 203, 204);
        &__title{
          border: 0 !important;
        }
        h5{
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
        }
        ul{
          display: none;
        }
        &:hover{
          ul{
            display: none;
          }
        }
      }
    }
  }

  .selected-tab-container {
   display: none;
  }

  .tab__single.active {
    background: #BADA55;
    h5 {
      color: #FFFFFF;
    }
    ul{
      background: #ffffff;
    }
  }
  @media(max-width: 767px){
    .selected-tab-container {
      margin-top: 30px;
      display: inherit !important;
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #ffffff;
        li{
          font-size: 18px;
          line-height: 26px;
          font-weight: 400;
          margin-bottom: 15px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export default MyComponent;
