import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Tab from "../../components/expertise/Tab";
import TextImg from "../../components/TextImg";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/expertise";
import {Loading} from "../../components/Loading";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyComponent = () => {
    gsap.registerPlugin(ScrollTrigger);
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.EXPERTISE
        dispath(fetchData([api_url]))
    }, [])

    let getPost = useSelector(state => state.expertise)

    const banner = getPost?.posts?.[0]?.banner;
    const service = getPost?.posts?.[0]?.services;
    const landscape = getPost?.posts?.[0]?.landscape;

    // bottom image & title
    const [bottomImg, setBottomImg] = useState('')
    const [bottomTitle, SetBottomTitle] = useState('')
    const [bottomDesc, setBottomDesc] = useState('')


    // tab fade in fade up animation
    useEffect(() => {
        let getAllMenu = document.querySelectorAll('.expertise-tab__menu li')

        getAllMenu.forEach(e => {
            e.addEventListener('click', function () {
                gsap.fromTo('.experties-page .expertise-tab__list,.experties-page .text-img', {
                    opacity: 0,
                }, {
                    opacity: 1,
                })
            });
        })


    }, [getPost])


    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [getPost])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Expertise | Saudi Landscape</title>
                <meta name="description" content="Design | Build | O&M"/>
            </Helmet>
            {getPost?.loading && <Loading/>}
            <StyledComponent className={'experties-page'}>
                <InnerBanner text={banner?.subtitle} img={banner?.image}
                             title={banner?.title}/>
                <Tab setBottomImg={setBottomImg} SetBottomTitle={SetBottomTitle} setBottomDesc={setBottomDesc}
                     data={service}/>
                {/*<TextImg subTitle={bottomTitle} desc={bottomDesc}*/}
                {/*         img={bottomImg}/>*/}
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .text-img__desc {
    h4 {
      font-size: 36px;
      line-height: 54px;
      font-weight: 400;
    }

    h4 {
      margin-bottom: 36px;
    }

    p {
      font-weight: 300;

    }
  }

  p {
    -webkit-line-clamp: unset !important
  }

  .text-img {
    padding-top: 0;
  }

  @media (max-width: 767px) {
    .text-img__desc p, .text-img__desc h4 {
      font-size: 30px !important;
      line-height: 44px !important;
    }
  }
`;

export default MyComponent;
