import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {


    return (
        <StyledComponent className={'address pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={5}>
                        <Title margin={'0 0 40px 0'} text={data?.title}/>
                        <div className="address__single fade-up">
                            <h4>Address</h4>
                            <p>{reactHtmlParser(data?.address)}</p>
                        </div>
                        <div className="address__single fade-up">
                            <h4>General Inquiries</h4>
                            <a href={`mailto:${reactHtmlParser(data?.general?.email.toLowerCase())}`}>{reactHtmlParser(data?.general?.email.toLowerCase())}</a>
                            <a href={`callto:${reactHtmlParser(data?.general?.call.toLowerCase())}`}>{reactHtmlParser(data?.general?.call.toLowerCase())}</a>

                        </div>
                        <div className="address__single fade-up">
                            <h4>Project inquiries </h4>
                            {/*{reactHtmlParser(data?.email)}*/}
                            <a href={`mailto:${reactHtmlParser(data?.project?.email.toLowerCase())}`}>{reactHtmlParser(data?.project?.email.toLowerCase())}</a>
                            <a href={`callto:${reactHtmlParser(data?.project?.call.toLowerCase())}`}>{reactHtmlParser(data?.project?.call.toLowerCase())}</a>
                        </div>

                    </Col>

                    <Col sm={{span: 7}}>
                        <div className="address__map">
                            <a href={data?.url} target={'_blank'}></a>
                            <Img src={data?.thumb} alt=""/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .address__single {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 40px;
    }

    h4 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      max-width: 70%;

      span {
        font-size: 16px;
        line-height: 32px;
        font-weight: 500;
      }
    }

    a {
      display: block;
    }

    &:nth-last-child(1) {
      a {
        text-transform: lowercase;
      }
    }
  }

  .address__map {
    padding-top: calc(440 / 635 * 100%);
    position: relative;
    height: 100%;

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }

  @media (max-width: 768px) {
    .col-sm-5, .col-sm-7 {
      min-width: 100%;
    }

    .address__map {
      margin-top: 40px;
    }

  }

  @media (max-width: 767px) {
    .address__single p {
      min-width: 100%;
    }
  }

`;

export default MyComponent;
