import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import TextImg from "../../components/TextImg";
import Why from "../../components/about/Why";
import {Loading} from "../../components/Loading";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import LandscapeMagazine from "../../components/press/LandscapeMagazine";
import DqMagazine from "../../components/press/DqMagazine";
import CertficateList from "../../components/certificate/CertficateList";
import RecognitionList from "../../components/certificate/RecognitionList";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/certificate";


const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CERTIFICATE
        dispath(fetchData([api_url]))
    }, [])

    let getPost = useSelector(state => state.certificate)

    console.log('getPost', getPost)

    //refactor
    const banner = getPost?.posts?.[0]?.banner;
    const certfications = getPost?.posts?.[0]?.certificates;
    const authorization = getPost?.posts?.[0]?.authorization;

    // animation
    useEffect(() => {
        ScrollTrigger.refresh();
        let allAnim = gsap.utils.toArray(".fade-up");
        console.log('allAnim', allAnim)
        allAnim.forEach((el, index) => {
            gsap.fromTo(
                el,
                {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Certificate | Saudi Landscape</title>
                <meta name="description" content="Design | Build | O&M"/>
            </Helmet>

            <StyledComponent className={'certificate'}>
                {getPost?.loading && <Loading/>}
                <InnerBanner noAnim text={banner?.subtitle} img={banner?.image}
                             title={banner?.title}/>
                <CertficateList data={certfications}/>
                <RecognitionList data={authorization}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  p {
    -webkit-line-clamp: initial;
  }
`;

export default MyComponent;
