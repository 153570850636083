import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import ProjectSingle from "../ProjectSingle";
import Button from "../Button";
import Title from "../Title";

const MyComponent = ({data, setClear}) => {

    const [item, setItem] = useState(6)

    const handleLoadMore = () => {
        setItem(item + 6)
    }

    return (
        <StyledComponent className={'project-list pb-120'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title margin={'0 0 40px 0'} text={'Featured Projects'}/>
                    </Col>
                    {
                        data && data?.length > 0 &&
                        data?.slice(0, item)?.map((e, i) => {
                            return (
                                <Col sm={4} key={i}>
                                    <ProjectSingle link={`/project/${e?.post_name}`} desc={e?.list?.subtitle}
                                                   title={e?.post_title}
                                                   img={e?.list?.image}/>
                                </Col>
                            );
                        })
                    }

                    {data?.length >= item &&
                        <Col sm={12}>
                            <div onClick={() => handleLoadMore()} style={{width: 'fit-content', margin: 'auto'}}>
                                <Button text={'Load More'}/>
                            </div>

                        </Col>
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .col-sm-4 {
    margin-bottom: 40px;
  }

  .dc-btn {
    margin: auto;
  }
`;

export default MyComponent;
