import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-120'}>
            <Container >
                <Title text={data?.title} margin={'0 0 40px'}/>
                <div className={'certificates'}>
                    {
                        data?.list && data?.list?.length>0 &&
                        data?.list?.map((e,i)=>{
                            return(
                                <div className={'certificates__single'} key={i}>
                                    <div className={'certificates__single__wrap'}>
                                        <h3>{e?.title}</h3>
                                        <p>{reactHtmlParser(e?.description)}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={'btn'}>
                    <Button src={'/certificate'} margin={'30px 0 0 0'} width={'200px'} text={'All Certificates'}/>
                </div>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .certificates{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, auto);
      &__single{
        border-top: 0.5px solid #CBCBCC;
        border-right: 0.5px solid #CBCBCC;
        &:first-child{
          border-left: 0.5px solid #CBCBCC;
        }
        :nth-child(4n + 1){
          border-left: 0.5px solid #CBCBCC;
        }
        &:nth-last-child(-n+4) {
          border-bottom: 0.5px solid #CBCBCC;
        }
        padding-top: 52px;
        padding-bottom: 52px;
        &__wrap{
          height: 120px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          h3{
            font-size: 34px;
            line-height: 50px;
            font-weight: 500;
          }
          p{
            margin-top: 5px;
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: 300;
            color: #545456;
            width: 85%;
          }
        }
      }
      @media(max-width: 767px){
        grid-template-columns: repeat(2, 1fr);
        &__single{
          padding-top: 25px;
          padding-bottom: 25px;
          border: 0.5px solid #CBCBCC !important;
          &:nth-child(even){
            border-left: 0 !important;
          }
          &:nth-child(2){
            border-top: 0.5px solid #CBCBCC !important;
          }
          &:nth-child(n + 3){
            border-top: 0 !important;
          }
          &__wrap{
            h3{
              font-size: 24px;
              line-height: 50px;
              font-weight: 500;
            }
            p{
              font-size: 12px;
              line-height: 16px;
              font-weight: 300;
            }
          }
        }
      }
    }
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    .dc-btn{
      a{
        padding: 17px 35px !important;
      }
    }
  }
`;

export default MyComponent;
