import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({desc, title, link, img, textOverflow, quotes}) => {
    return (
        <StyledComponent textOverflow={textOverflow} className={'text-img pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={6} className={'text-img__desc'}>
                        {title && <Title margin={'0 0 40px 0'} text={title}/>}
                        <p className={'fade-up'}>{reactHtmlParser(desc)}</p>

                        <h4 className={'fade-up'}>{reactHtmlParser(quotes)} </h4>
                    </Col>

                    <Col sm={{span: 5, offset: 1}} className={'text-img__img'}>
                        <div className="text-img__img__wrap">
                            <Img src={img}/>
                        </div>
                        {link && <Button src={link} margin={'30px 0 0 0'} width={'200px'} text={'about us'}/>}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }

  h4 {
    position: relative;
    margin-top: 60px;
    font-weight: 600;
    font-style: italic;
    //padding-left: 42px;
    font-size: 24px;
    line-height: 36px;


    img {
      position: absolute;
      left: 0;
      height: 70px;
      top: -25px;
      z-index: -1;
    }
  }


  .text-img__img {
    &__wrap {
      position: relative;
      padding-top: calc(600 / 500 * 100%);
    }

    @media (min-width: 769px) {
      .dc-btn {
        display: none;
      }
    }
  }


  @media (max-width: 768px) {
    .text-img__desc, .text-img__img {
      min-width: 100%;
      margin: 0;
    }

    .text-img__desc {
      margin-bottom: 40px;

      .dc-btn {
        display: none;
      }
    }

    h4 {
      margin-top: 40px;
      font-size: 18px;
      line-height: 26px;
      //padding-left: 22px;

      img {
        height: 40px;
        top: -10px;
      }
    }
  }

  @media (max-width: 767px) {
    .text-img__desc {
      h4 {
        font-size: 18px !important;
        line-height: 26px !important;
      }

      p {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }



`;

export default MyComponent;
