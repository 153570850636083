export const apiEndPoints = {
    PAGE_DATA:'page-data',
    POSTS: 'posts',
    HOME: 'page/home',
    ABOUT: 'page/about',
    EXPERTISE: 'page/expertise',
    PROJECTS: 'page/projects',
    VENDORS: 'page/vendors',
    CAREER: 'page/career',
    CONTACT: 'page/contact-us',
    GLOBAL: 'page/global',
    PRESS: 'page/press',
    CERTIFICATE: 'page/certificate',
    PROJECT_DETAILS: 'project',
    CONTACT_FORM: 'contact-form-7/v1/contact-forms/66/feedback',
    CAREER_FORM: 'contact-form-7/v1/contact-forms/65/feedback',
    HOME_FORM: 'contact-form-7/v1/contact-forms/58/feedback',
    VENDOR_FORM: 'contact-form-7/v1/contact-forms/59/feedback',
};
