import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";

const MyComponent = ({title, desc, link, img}) => {
    return (
        <StyledComponent className={'project-single'}>
            <Link to={link}/>
            <div className="project-single__img">
                <Img src={img}/>
            </div>
            <div className="project-single__content">
                <h4>{title}</h4>
                <p>{desc}</p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 15px;
  position: relative;
  height: 100%;

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .project-single__img {
    padding-top: calc(460 / 370 * 100%);
    position: relative;
    overflow: hidden;

    img {
      transform: scale(1.01);
      transition: 1.4s ease;
    }
  }

  .project-single__content {
    margin-top: 20px;

    h4 {
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      font-weight: 400;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      color: #545456;
      text-transform: uppercase;
    }
  }

  &:hover {
    .project-single__img img {
      transform: scale(1.05);
    }
  }

  @media (max-width: 767px) {
    .project-single__content {
      h4 {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
`;

export default MyComponent;
