import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {Img} from "../Img";

const MyComponent = ({data, setBottomDesc, SetBottomTitle, setBottomImg}) => {

    const [filterItem, setFilterItem] = useState('survey')

    const mappedData = data?.[filterItem]

    const handleFiler = (e) => {
        setFilterItem(e)
        setBottomDesc(mappedData?.desc)
        SetBottomTitle(mappedData?.title)
        setBottomImg(mappedData?.image)
    }

    useEffect(() => {
        setBottomDesc(mappedData?.desc)
        SetBottomTitle(mappedData?.title)
        setBottomImg(mappedData?.image)
    }, [mappedData, setBottomDesc, SetBottomTitle, setBottomImg])


    return (
        <StyledComponent className={'expertise-tab pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={12} className={'expertise-tab__menu'}>
                        <ul>
                            <li onClick={() => handleFiler('survey')}
                                className={filterItem === 'survey' ? 'active' : ''}>Survey
                            </li>
                            <li onClick={() => handleFiler('design')}
                                className={filterItem === 'design' ? 'active' : ''}>Design
                            </li>
                            <li onClick={() => handleFiler('build')}
                                className={filterItem === 'build' ? 'active' : ''}>Build
                            </li>
                            <li onClick={() => handleFiler('om')} className={filterItem === 'om' ? 'active' : ''}>O&M
                            </li>
                            <li onClick={() => handleFiler('activation')}
                                className={filterItem === 'activation' ? 'active' : ''}>Activation
                            </li>

                        </ul>
                    </Col>

                    <Col sm={12}>
                        <div className="expertise-tab__list">

                            {/*<div className="expertise-tab__list__desc fade-up">*/}
                            {/*    <p>{reactHtmlParser(mappedData?.desc)}</p>*/}
                            {/*</div>*/}

                            <div className="expertise-tab__list__row fade-up">
                                <ul>
                                    {mappedData?.list?.length > 0 && mappedData?.list?.map((e, i) => (
                                        <li key={i}>{reactHtmlParser(e?.title.toLowerCase())}
                                            {/*<span> <img src="/images/static/dash.svg"*/}
                                            {/*            alt=""/> {e?.desc}</span>*/}
                                        </li>
                                    ))}
                                </ul>

                                <div className="expertise-tab__list__row__img">
                                    <div className="expertise-tab__list__row__img__img">
                                        <Img src={mappedData?.image}/>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .expertise-tab__menu {
    ul {
      display: flex;
      //border-bottom: 1px solid rgba(84, 84, 86, 0.3);
      margin-bottom: 40px;

      li {
        text-transform: uppercase;
        font-size: 22px;
        line-height: 20px;
        font-weight: 500;
        flex: 1;
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s ease;

        &:not(:nth-last-of-type(1)) {
          border-right: 1px solid rgba(84, 84, 86, 0.3);
        }

        &:hover {
          background-color: #E9E9E9;
          //color: #ffffff;
        }

        &.active {
          background-color: ${hover};
          color: #ffffff;
        }
      }
    }
  }


  .expertise-tab__list {

    &__desc {
      margin-bottom: 40px;

      p {
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;

      ul {
        width: 50%;
      }

      &__img {
        width: 50%;

        &__img {
          padding-top: calc(660 / 500 * 100%);
          position: relative;

        }
      }
    }


    ul {
      //display: flex;
      flex-wrap: wrap;
      padding-left: 22px;
      padding-right: 60px;

      li {
        font-weight: 400;
        //text-transform: capitalize;
        list-style-type: disc;
        //width: 25%;
        padding-left: 15px;
        line-height: 28px;
        margin-bottom: 10px;
        //padding-right: 25px;
        font-size: 20px;
        padding-top: 10px;
        padding-bottom: 10px;

        &::first-letter {
          text-transform: capitalize;
        }

        br {
          display: none;
        }

        span {
          font-weight: 300;
          font-style: italic;
          font-size: 20px;
          line-height: 32px;
          //opacity: 0;
          transition: opacity .5s ease;
          position: relative;
          display: inline-block;

          &:after {
            content: '';
            height: 100%;
            width: 100%;
            right: 0px;
            position: absolute;
            background-color: #FFF;
            top: 0;
            bottom: 0;
            transition: .4s ease;
          }

          img {
            margin-right: 30px;
            margin-left: 30px;
          }
        }

        &:hover {
          span {
            opacity: 1;

            &:after {
              width: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .expertise-tab__menu {
      ul {
        li {
          font-size: 18px;
          line-height: 20px;
          font-weight: 600;
          padding-top: 5px;
        }
      }
    }

    .expertise-tab__list {
      ul li span {
        opacity: 1;
        display: block;
        margin-top: 10px;

        &:after {
          display: none;
        }

        img {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .expertise-tab__list ul {
      width: 100%;
      padding-right: 0px;
    }

    .expertise-tab__list__row__img {
      width: 100%;
      margin-top: 20px;

    }
  }


  @media (max-width: 767px) {
    padding-bottom: 80px;
    .expertise-tab__menu {
      ul {
        flex-wrap: wrap;
        border: 0;
        margin-bottom: 40px;

        li {
          max-width: 50%;
          min-width: 50%;
          //margin-bottom: 9px;
          border-right: 0 !important;
          border-bottom: 1px solid rgba(84, 84, 86, 0.3);
          padding-bottom: 5px;
          position: relative;
          height: 60px;

          &:nth-of-type(odd) {
            &:after {
              position: absolute;
              height: calc(100% - 15px);
              width: 1px;
              background-color: rgba(84, 84, 86, 0.3);
              right: 0;
              content: '';
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }

          &.active:after {
            display: none;
          }
        }
      }
    }

    .expertise-tab__list {
      ul {
        padding-left: 20px;
        flex-wrap: wrap;

        li {
          min-width: 100%;
          margin-bottom: 20px;
          padding-left: 15px;
          font-size: 18px;
          line-height: 28px;
          padding-top: 0;
          padding-bottom: 0;

          br {
            display: none;
          }
        }
      }
    }

    .expertise-tab__list__desc p {
      font-size: 18px;
      line-height: 28px;
    }
  }




`;

export default MyComponent;
