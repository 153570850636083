import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import ProjectSingle from "../ProjectSingle";
import Button from "../Button";
import Title from "../Title";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {Navigation, Pagination, Autoplay} from "swiper";


const MyComponent = ({data}) => {
    let sliderRefIcon = useRef();
    return (
        <StyledComponent className={'feature-projects pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title text={'Featured Projects'} margin={'0 0 40px 0'}/>
                    </Col>
                </Row>
                <Swiper ref={sliderRefIcon}
                        slidesPerView={1}
                        slideNextClass={'--next'}
                        allowSlideNext={true}
                        slidePrevClass={'--prev'}
                        allowSlidePrev={true}
                        allowTouchMove={true}
                        longSwipesMs={900}
                        speed={900}
                        autoplay={{
                            delay: 8500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        slidesPerGroup={1}
                        slidesPerGroupAuto={true}
                        spaceBetween={30}
                        breakpoints={{
                            650: {
                                slidesPerView: 2,
                                // spaceBetween: 15,
                                slidesPerGroup: 2
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                                slidesPerGroup: 2
                            },
                        }}
                    // navigation={{
                    //     prevEl: '.client-slider .prev_swippers',
                    //     nextEl: '.client-slider .next_swippers  ',
                    // }}
                        modules={[Navigation, Pagination, Autoplay]}
                    // onSlideChange={(swiper) => console.log(swiper.activeIndex)}
                >
                    {
                        data && data?.length > 0 &&
                        data?.map((e, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <ProjectSingle img={e?.image} link={`/project/${e?.post_name}`}
                                                   desc={e?.subtitle}
                                                   title={e?.post_title}/>
                                </SwiperSlide>
                            );
                        })
                    }

                </Swiper>

                <Col sm={12} className={'p-0'}>
                    <Button src={'/projects'} text={'All projects'}/>
                </Col>


            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .dc-btn {
    margin: 40px auto;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-pagination-bullet {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    //border: 1px solid rgba(84, 84, 86, 0.6);
    margin-right: 10px !important;
    background: transparent;
    opacity: 1;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: #545456;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .swiper-pagination-bullet-active {
    border: 1px solid rgba(84, 84, 86, 0.6);
  }

  @media (max-width: 768px) {
    .swiper-wrapper {
      padding-bottom: 60px;
    }

    .swiper-pagination {
      display: block;
    }

  }

`;

export default MyComponent;
