import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Title from "../Title";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postData} from "../../api/redux/vendor";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {

    // form submission
    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const dispatch = useDispatch()
    const apiEndPoint = apiEndPoints.VENDOR_FORM


    const handleFormSubmit = (e) => {
        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.number);
        formData.append('your-business', e?.business);
        formData.append('your-message', e?.message);
        dispatch(postData([apiEndPoint, formData]))
    }

    useEffect(() => {
        if (isSubmitSuccessful) {
            success('Thank you for your message')
            reset()
        }
    }, [isSubmitSuccessful])


    return (
        <StyledComponent className={'inquires pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={4}>
                        <Title margin={'0 0 40px 0'} text={data?.title}/>
                        {reactHtmlParser(data?.description)}
                        
                    </Col>

                    <Col sm={{span: 6, offset: 2}}>
                        <Form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: true,
                                        message: "Please enter your full name",
                                    }

                                })} type="text" placeholder="Company name *"/>
                                <p className={'form-error'}>{errors.name?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('email', {
                                    required: {
                                        value: true,
                                        message: "Please enter a valid email address"
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })} type="email" placeholder="Email *"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('number')} type="number" placeholder="Phone number"/>
                            </Form.Group>
                            <Form.Group className={'from-group'}>
                                <Form.Control {...register('message')} type="text" placeholder="Website"/>
                            </Form.Group>

                            {errors.email || errors.name ?
                                <div className="button-wrap justify-content-end d-flex disabled">
                                    <Button margin={'30px 0 0 0'} text={'Send'}/>
                                </div>
                                :
                                <div className="button-wrap justify-content-end d-flex"
                                     onClick={handleSubmit(handleFormSubmit)}>
                                    <Button margin={'30px 0 0 0'} text={'Send'}/>
                                </div>

                            }

                        </Form>
                    </Col>


                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  h4 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    color: #212121;
  }

  .form-control {
    border: none;
    border-bottom: 1px solid rgba(194, 194, 194, 0.3);
    border-radius: 0;
    padding: 20px 20px 24px 0;
    margin-bottom: 20px;

    ::placeholder {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      color: #a4a4a4;
    }
  }

  textarea {
    min-height: 55px;
    height: 53px;
    max-height: 100px;
  }

  @media (max-width: 575px) {
    form {
      margin-top: 60px;
    }
  }
`;

export default MyComponent;
